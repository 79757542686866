import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack'
import { useLocation, useHistory, withRouter } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { injectIntl } from '../../util/reactIntl';

import {
  LayoutSingleColumn,
  Page,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

// Sections
import SectionHero from './SectionHero/SectionHero';
import SectionCategories from './SectionCategories/SectionCategories';
import SectionLocations from './SectionLocations/SectionLocations';
import SectionSorted from './SectionSorted/SectionSorted';
import SectionGetToKnow from './SectionGetToKnow/SectionGetToKnow';
import SectionPartners from './SectionPartners/SectionPartners';
import SectionMetrics from './SectionMetrics/SectionMetrics';
import SectionProgram from './SectionProgram/SectionProgram';

// Ducks
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';

import { useConfiguration } from '../../context/configurationContext';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { scrollingDisabled, intl } = props;
  const config = useConfiguration();


  const marketplaceName = config.marketplaceName;

  const title = intl.formatMessage({ id: 'LandingPage.schemaTitle'}, { marketplaceName });
  const topBar = <TopbarContainer />;

  useEffect(() => {
    if (location.state && location.state.from && location.state.from.startsWith("/l/draft/") ) {
      enqueueSnackbar(
        'This action is not allowed for your role, you need to be logged in as an Equipment provider to post the equipment',
        {
          autoHideDuration: 5000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "warning"
        }
      );
      history.replace();
    }
  }, []);

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn mainColumnClassName={css.main}  rootClassName={css.main} className={css.pageRoot} topbar={topBar} footer={<FooterContainer />}>
        <div className={css.root}>
          <section className={css.heroContainer}>
            <SectionHero history={history} location={location} />
          </section>
          <section className={css.contentWrapper}>
            <div className={css.content}>
              <section>
                <SectionProgram history={history} />
              </section>

              <section>
                <SectionCategories />
              </section>

              <section>
                <SectionLocations />
              </section>

              <section>
                <SectionSorted />
              </section>

              <section>
                <SectionGetToKnow />
              </section>

              <section>
                <SectionPartners />
              </section>

              <section>
                <SectionMetrics />
              </section>
            </div>
          </section>

        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
