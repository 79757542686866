import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import AliceCarousel from 'react-alice-carousel';

import { NamedLink } from '../../../components';
import { categories, locations } from '../data';
import rightArrow from '../images/arrows/right.svg';
import leftArrow from '../images/arrows/left.svg';

import css from './SectionCategories.module.css';


const items = categories.map((item) => {
  return <div  key={item.title} className={css.itemCarousel}>
    <div className={css.itemCarouselTitleHolder}>
      <h3 className={css.itemCarouselTitle}>{item.title}</h3>
      <p className={css.itemCarouselDescription}>{item.description}</p>
      <NamedLink className={css.itemCarouselLink} name="SearchPage">
        {item.linkText}
        <img className={css.rightArrow} src={rightArrow} alt='Arrow' />
      </NamedLink>

    </div>
    {/*<div className={css.itemCarouselImgHolder}>*/}
    {/*  <img className={css.itemCarouselImg} src={item.img} alt={item.title} />*/}
    {/*</div>*/}


  </div>
})

const thumbs = categories.map((thumb, index) => {
  return <div key={index} className={css.thumbItem}>
    <img className={css.thumbImg} src={thumb.img} alt='' />
  </div>
})

const SectionCategories = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const [mainIndex, setMainIndex] = useState(0);
  const [mainAnimation, setMainAnimation] = useState(false);
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);

  const slideNext = () => {
    if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex + 1);
    }
  };

  const slidePrev = () => {
    if (!thumbAnimation && thumbIndex > 0) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex - 1);
    }
  };

  const syncThumbs = (e) => {
    setThumbIndex(e.item);
    setThumbAnimation(false);

    if (!mainAnimation) {
      setMainIndex(e.item);
    }
  };

  return (
    <div className={classes}>
      <div className={css.content}>
        <h3 className={css.heroMainTitle}>
          <FormattedMessage id="SectionCategories.title" />
        </h3>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionCategories.subTitle" />
        </h2>

        <div className={css.carouselHolder}>
          <div className={css.items}>
            <div  className={css.itemCarousel}>
              <div className={css.itemCarouselTitleHolder}>
                <h3 className={css.itemCarouselTitle}>{categories[thumbIndex].title}</h3>
                <p className={css.itemCarouselDescription}>{categories[thumbIndex].description}</p>
                <NamedLink className={css.itemCarouselLink} name="SearchPage" to={{search: `?pub_masterCategory=${categories[thumbIndex].categoryName}`}}>
                  {categories[thumbIndex].linkText}
                  <span className={css.rightArrow} />
                </NamedLink>
              </div>
            </div>
          </div>

          <div className={css.thumbs}>
            <AliceCarousel
              activeIndex={thumbIndex}
              autoWidth
              disableDotsControls
              disableButtonsControls
              items={thumbs}
              mouseTracking={false}
              onSlideChanged={syncThumbs}
              touchTracking={!mainAnimation}
            />
            <div className={css.buttonsHolder}>
              <button className={css.buttonPrev} onClick={slidePrev}>
                <img className={css.buttonArrow} src={leftArrow} alt='Arrow' />
              </button>
              <div className={css.counter}>
                <span>{thumbIndex + 1}/</span>
                <span>{categories.length}</span>
              </div>
              <button className={css.buttonNext} onClick={slideNext}>
                <img className={css.buttonArrow} src={rightArrow} alt='Arrow' />
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

SectionCategories.defaultProps = { rootClassName: null, className: null };

SectionCategories.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCategories;
