import React, { useState, useEffect, useRef } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionHero.module.css';
import { NamedLink } from '../../../components';

// images
import heroImage from '../images/hero/heroImage.png';
import heroImageMobile from '../images/hero/heroImageMobile.png';

const SectionHero = props => {
  const { rootClassName, className } = props;
  let classes = classNames(rootClassName || css.root, css.hero, className);
  const prevScrollY = useRef(0);
  const heroContainer = useRef(null);
  const heroImageHolder = useRef(null);
  const [goingUp, setGoingUp] = useState(false);

  const [isMobileLayout, setMobileLayout] = useState(false)

  useEffect(() => {

    const viewportWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    setMobileLayout(viewportWidth <= 767);

  }, [isMobileLayout]);

  useEffect(() => {

    if(typeof window !== 'undefined') {
      const handleScroll = () => {
        const currentScrollY = window.scrollY;
        const currentHeroImageHolder = heroImageHolder.current;
        const currentHeroContainer = heroContainer.current;

        if(currentScrollY > 50 && currentScrollY < 100) {
          currentHeroImageHolder.className = classNames(css.heroImageHolder, css.scroll50);
          currentHeroContainer.className = classNames(css.root, css.hero, css.hero50, className);
        }
        if(currentScrollY > 100 && currentScrollY < 150) {
          currentHeroImageHolder.className = classNames(css.heroImageHolder, css.scroll100);
          currentHeroContainer.className = classNames(rootClassName || css.root, css.hero, css.hero100, className);
        }
        if(currentScrollY > 150 && currentScrollY < 200) {
          currentHeroImageHolder.className = classNames(css.heroImageHolder, css.scroll150);
          currentHeroContainer.className = classNames(rootClassName || css.root, css.hero, css.hero150, className);
        }
        if(currentScrollY > 200 && currentScrollY < 250) {
          currentHeroImageHolder.className = classNames(css.heroImageHolder, css.scroll200);
          currentHeroContainer.className = classNames(rootClassName || css.root, css.hero, css.hero200, className);
        }

        if (prevScrollY.current < currentScrollY && goingUp) {
          setGoingUp(false);
        }
        if (prevScrollY.current > currentScrollY && !goingUp) {
          setGoingUp(true);
        }

        prevScrollY.current = currentScrollY;
      };

      window.addEventListener("scroll", handleScroll, { passive: true });

      return () => window.removeEventListener("scroll", handleScroll);
    }

  }, [goingUp]);

  return (
    <div ref={heroContainer} className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroDescription}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage
              id="SectionHero.title"
              values={{
                span1: <span className={css.boldTitle}>Hire</span>,
                span2: <span className={css.boldTitle}>Buy</span>
            }}
            />
          </h1>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>

          <NamedLink name="SearchPage" to={{search: '?address=Ireland&bounds=55.46020003387486%2C-5.66890006746988%2C51.30659995812083%2C-10.76660009801868'}} className={css.heroButton}>
            <FormattedMessage id="SectionHero.button" />
          </NamedLink>
        </div>
        <div ref={heroImageHolder} className={css.heroImageHolder}>
          <img className={css.heroImage} src={isMobileLayout ? heroImageMobile : heroImage} alt='Hero image' />
        </div>


      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
