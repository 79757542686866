import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionPartners.module.css';
import { NamedLink } from '../../../components';
import { partnerLogos } from '../data';
import rightArrow from '../images/arrows/rightBlue.svg';

const partnerLogoItems = partnerLogos.map((item, index) => {
  return <a href={item.href} target='_blank' key={index} className={classNames(css.logoItem, css[item.companyName])} />
})

const SectionPartners = props => {

  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.block}>
          <h3 className={css.mainTitle}>
            <FormattedMessage id="SectionPartners.title" />
          </h3>
          <h4 className={css.subTitle}>
            <FormattedMessage id="SectionPartners.subTitle" />
          </h4>

          <NamedLink className={css.link} name="PartnerPortal">
            <FormattedMessage id="SectionPartners.more" />
            <span className={css.rightArrow} />
          </NamedLink>
        </div>

        <div className={css.logosHolder}>
          {partnerLogoItems}
        </div>


      </div>
    </div>
  );
};

SectionPartners.defaultProps = { rootClassName: null, className: null };

SectionPartners.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPartners;
