import React, { useState, useEffect, useRef } from 'react';
import PropTypes, { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import { metrics } from '../data';

import css from './SectionMetrics.module.css';
import AliceCarousel from 'react-alice-carousel';
import leftArrow from '../images/arrows/leftBlack.svg';
import rightArrow from '../images/arrows/rightBlack.svg';

const handleDragStart = (e) => e.preventDefault();

const items = metrics.map((item) => {
  return <div  key={item.title} className={css.itemCarousel}>
    <img className={css.itemCarouselImg} src={item.img} alt={item.title} />
    <div className={css.dataCarouselHolder}>
      <span className={css.itemCarouselCounter}>{item.counter}</span>
      <span className={css.itemCarouselTitle}>{item.title}</span>
    </div>

  </div>
})

const SectionMetrics = props => {
  const carousel = useRef(null);

  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobileLayout, setMobileLayout] = useState(false)

  useEffect(() => {

    const viewportWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    setMobileLayout(viewportWidth <= 1023);

  }, [isMobileLayout]);

  const slidePrev = () => {
    const step = isMobileLayout ? 1 : 4;
    return carousel?.current?.slideTo(activeIndex - step);
  }

  const slideNext = () => {
    const step = isMobileLayout ? 1 : 4;
    return carousel?.current?.slideTo(activeIndex + step);
  }

  const syncActiveIndexForSwipeGestures = (e) => setActiveIndex(e.item);
  const onSlideChanged = (e) => {
    syncActiveIndexForSwipeGestures(e);
  };

  return (
    <div className={classes}>
      <div className={css.content}>
        <h3 className={css.mainTitle}>
          <FormattedMessage id="SectionMetrics.title" />
        </h3>
        <h2 className={css.subTitle}>
          <FormattedMessage id="SectionMetrics.subTitle" />
        </h2>

        <div className={css.carouselHolder}>
          <AliceCarousel
            mouseTracking
            items={items}
            disableButtonsControls
            onSlideChanged={onSlideChanged}
            slideTo={slideNext}
            activeIndex={activeIndex}
            ref={carousel}
            responsive={{
              0: {
                items: 1,
              },
              420: {
                items: 1,
              },
              1024: {
                items: 4,
              }
            }}
          />
          <div className={css.buttonsHolder}>
            <button className={css.buttonPrev} onClick={slidePrev}>
              <img className={css.buttonArrow} src={leftArrow} alt='Arrow' />
            </button>
            <button className={css.buttonNext} onClick={slideNext}>
              <img className={css.buttonArrow} src={rightArrow} alt='Arrow' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionMetrics.defaultProps = { rootClassName: null, className: null };

SectionMetrics.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionMetrics;
