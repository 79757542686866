import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import { sortedEquipment } from '../data';

import css from './SectionSorted.module.css';
import { NamedLink } from '../../../components';
// import rightArrowWhite from '../images/arrows/right.svg';
// import rightArrowBlack from '../images/arrows/rightBlack.svg';

const blockItems = sortedEquipment.map((item, index) => {
  return <li key={index} className={css.itemHolder}>
    <div className={css.itemTitleHolder}>
      <h3 className={css.itemTitle}>{item.title}</h3>
      <p className={css.itemDescription}>{item.description}</p>
    </div>

    <div className={css.itemImageHolder}>
      <img className={css.itemImage} src={item.img} alt={item.title} />
    </div>
    <NamedLink
      className={index === 0 ? classNames(css.itemLink) : classNames(css.itemLink, css.itemLinkSecond)}
      name={item.linkPage}
    >
      {item.linkText}
      <span className={css.rightArrow} />
    </NamedLink>

  </li>
})

const SectionSorted = props => {

  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <h3 className={css.mainTitle}>
          <FormattedMessage id="SectionSorted.title" />
        </h3>
        <h4 className={css.subTitle}>
          <FormattedMessage id="SectionSorted.subTitle" />
        </h4>

        <ul className={css.itemsList}>{blockItems}</ul>

      </div>
    </div>
  );
};

SectionSorted.defaultProps = { rootClassName: null, className: null };

SectionSorted.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSorted;
