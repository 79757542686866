// categories
import bulldozers from './images/categories/bulldozers.svg';
import airCompressors from './images/categories/air_compressors.svg';
import cabling from './images/categories/cabling.svg';
import climateControl from './images/categories/climate_control.svg';
import cleaningEquipment from './images/categories/cleaning_equipment.svg';
import compactors from './images/categories/compactors.svg';
import concreteEquipment from './images/categories/concrete_equipment.svg';
import cranes from './images/categories/cranes.svg';
import dumpers from './images/categories/dumpers.svg';
import excavators from './images/categories/excavators.svg';
import forklifts from './images/categories/forklifts.svg';
import garden_landscape from './images/categories/garden_landscape.svg';
import generators from './images/categories/generators.svg';
import lighting from './images/categories/lighting.svg';
import loaders from './images/categories/loaders.svg';
import material_handling_equipment from './images/categories/material_handling_equipment.svg';
import mobile_elevated_work_platforms from './images/categories/mobile_elevated_work_platforms.svg';
import plumbing from './images/categories/plumbing.svg';
import power_tools from './images/categories/power_tools.svg';
import pumps from './images/categories/pumps.svg';
import scaffolding from './images/categories/scaffolding.svg';
import skip from './images/categories/skip.svg';
import welding_equipment from './images/categories/welding_equipment.svg';
import wellfare_units from './images/categories/wellfare_units.svg';

// locations
import co_antrim from './images/locations/co_antrim.jpg';
import co_armagh from './images/locations/co_armagh.jpg';
import co_carlow from './images/locations/co_carlow.jpg';
import co_cavan from './images/locations/co_cavan.jpg';
import co_clare from './images/locations/co_clare.jpg';
import co_cork from './images/locations/co_cork.jpg';
import co_derry from './images/locations/co_derry.jpg';
import co_donegal from './images/locations/co_donegal.jpg';
import co_down from './images/locations/co_down.jpg';
import co_dublin from './images/locations/co_dublin.jpg';
import co_fermanagh from './images/locations/co_fermanagh.jpg';
import co_galway from './images/locations/co_galway.jpg';
import co_kerry from './images/locations/co_kerry.jpg';
import co_kildare from './images/locations/co_kildare.jpg';
import co_kilkenny from './images/locations/co_kilkenny.jpg';
import co_laois from './images/locations/co_laois.jpg';
import co_leitrim from './images/locations/co_leitrim.jpg';
import co_limerick from './images/locations/co_limerick.jpg';
import co_longford from './images/locations/co_longford.jpg';
import co_louth from './images/locations/co_louth.jpg';
import co_mayo from './images/locations/co_mayo.jpg';
import co_meath from './images/locations/co_meath.jpg';
import co_monaghan from './images/locations/co_monaghan.jpg';
import co_offaly from './images/locations/co_offaly.jpg';
import co_roscommon from './images/locations/co_roscommon.jpg';
import co_sligo from './images/locations/co_sligo.jpg';
import co_tipperary from './images/locations/co_tipperary.jpg';
import co_tyrone from './images/locations/co_tyrone.jpg';
import co_waterford from './images/locations/co_waterford.jpg';
import co_westmeath from './images/locations/co_westmeath.jpg';
import co_wexford from './images/locations/co_wexford.jpg';
import co_wicklow from './images/locations/co_wicklow.jpg';


// equipment
import equipment1 from './images/equipment/procure.png';
import equipment2 from './images/equipment/selling.png';

import program1 from './images/program/trophy.png';
import program2 from './images/program/key.png';

// partners
import partnerImg1 from './images/partners/wicklow.svg';
import partnerImg1Color from './images/partners/wicklow_color.svg';
import partnerImg2 from './images/partners/expert.svg';
import partnerImg2Color from './images/partners/expert_color.svg';
import partnerImg3 from './images/partners/rota.svg';
import partnerImg3Color from './images/partners/rota_color.svg';
import partnerImg4 from './images/partners/builders_hoists.svg';
import partnerImg4Color from './images/partners/builders_hoists_color.svg';
import partnerImg5 from './images/partners/cyril_j.svg';
import partnerImg5Color from './images/partners/cyril_j_color.svg';
import partnerImg6 from './images/partners/kells_hire.svg';
import partnerImg6Color from './images/partners/kells_hire_color.svg';
import partnerImg7 from './images/partners/sme.svg';
import partnerImg7Color from './images/partners/sme_color.svg';
import partnerImg8 from './images/partners/vms.svg';
import partnerImg8Color from './images/partners/vms_color.svg';


// metrics

import hires from './images/metrics/hires.png';
import partners from './images/metrics/partners.png';
import siteVisits from './images/metrics/site_visits.png';
import revenue from './images/metrics/revenue.png';
import sales from './images/metrics/bank_note.png';
import activeListing from './images/metrics/cart.png'
import activeTenders from './images/metrics/trophy.png';
import verifiedPlatform from './images/metrics/users_check.png';

export const categories = [
  {
    img: bulldozers,
    categoryName: 'bulldozers',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Bulldozers'
  },
  {
    img: airCompressors,
    categoryName: 'air_compressors',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Air Compressors'
  },
  {
    img: cabling,
    categoryName: 'cabling',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Cabling'
  },
  {
    img: climateControl,
    categoryName: 'climateControl',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Climate Control'
  },
  {
    img: cleaningEquipment,
    categoryName: 'cleaningEquipment',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Cleaning Equipment'
  },
  {
    img: compactors,
    categoryName: 'compactors',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Compactors'
  },
  {
    img: concreteEquipment,
    categoryName: 'concreteEquipment',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Concrete Equipment'
  },
  {
    img: cranes,
    categoryName: 'cranes',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Cranes'
  },
  {
    img: dumpers,
    categoryName: 'dumpers',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Dumpers'
  },
  {
    img: excavators,
    categoryName: 'excavators',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Excavators'
  },
  {
    img: forklifts,
    categoryName: 'forklifts',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Forklifts'
  },
  {
    img: garden_landscape,
    categoryName: 'garden_landscape',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Garden Landscape'
  },
  {
    img: generators,
    categoryName: 'generators',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Generators'
  },
  {
    img: lighting,
    categoryName: 'lighting',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Lighting'
  },
  {
    img: loaders,
    categoryName: 'loaders',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Loaders'
  },

  {
    img: material_handling_equipment,
    categoryName: 'material_handling_equipment',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Material Handling Equipment '
  },
  {
    img: mobile_elevated_work_platforms,
    categoryName: 'mobile_elevated_work_platforms',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Mobile Elevated Work Platforms'
  },
  {
    img: plumbing,
    categoryName: 'plumbing',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See plumbing'
  },
  {
    img: power_tools,
    categoryName: 'power_tools',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Power Tools'
  },
  {
    img: pumps,
    categoryName: 'pumps',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Pumps'
  },
  {
    img: scaffolding,
    categoryName: 'scaffolding',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Scaffolding'
  },
  {
    img: skip,
    categoryName: 'skip',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Skip'
  },
  {
    img: welding_equipment,
    categoryName: 'welding_equipment',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Welding Equipment'
  },
  {
    img: wellfare_units,
    categoryName: 'wellfare_units',
    title: 'Our Most Popular Equipment Categories',
    description: 'Effortlessly navigate through our master categories to find the equipment you need, \n' +
      'each category thoughtfully curated to make \n' +
      'your search straightforward. From construction essentials to specialised tools, we\'ve organised our selection for your convenience.',
    linkText: 'See Wellfare Units'
  },
];

export const locations = [
  {
    img: co_antrim,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Antrim',
    bounds: 'bounds=55.3129104396658%2C-5.688022890838925%2C54.48676327847312%2C-6.637620679967415'
  },
  {
    img: co_armagh,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Armagh',
    bounds: 'bounds=54.57279696191629%2C-6.29157447730604%2C54.03648705583566%2C-6.877988584286946',
  },
  {
    img: co_carlow,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Carlow',
    bounds: 'bounds=52.91796280288468%2C-6.504910654373425%2C52.46360152951842%2C-7.107934740975522',
  },
  {
    img: co_cavan,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Cavan',
    bounds: 'bounds=54.30454795754888%2C-6.762007069527515%2C53.76557436292969%2C-8.05823247010779',
  },
  {
    img: co_clare,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Clare',
    bounds: 'bounds=53.16876934873046%2C-8.282424079721054%2C52.55451601056571%2C-9.93869376942437',
  },
  {
    img: co_cork,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Cork',
    bounds: 'bounds=52.38796031811967%2C-7.841127580406181%2C51.41989662071006%2C-10.2378995388594',
  },
  {
    img: co_derry,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Derry',
    bounds: 'bounds=55.21303275875703%2C-6.471928756437951%2C54.64111950036572%2C-7.407398725076613',
  },
  {
    img: co_donegal,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Donegal',
    bounds: 'bounds=55.43514022724059%2C-6.921964425576265%2C54.45906128372782%2C-8.833350082352528',
  },
  {
    img: co_down,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Down',
    bounds: 'bounds=54.68010832222112%2C-5.432768552072956%2C54.02270789462821%2C-6.405901960187824',
  },
  {
    img: co_dublin,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Dublin',
    bounds: 'bounds=53.63472574215852%2C-5.996274835892207%2C53.17819706827235%2C-6.546879781853942',
  },
  {
    img: co_fermanagh,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Fermanagh',
    bounds: 'bounds=54.60744754419427%2C-7.14134793138579%2C54.1132880427419%2C-8.177400082375879',
  },
  {
    img: co_galway,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Galway',
    bounds: 'bounds=53.71891463978044%2C-7.967334018796616%2C52.96815035449897%2C-10.3023053734866',
  },
  {
    img: co_kerry,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Kerry',
    bounds: 'bounds=52.6024524540674%2C-9.118785967032535%2C51.68832228988562%2C-10.61865957356386',
  },
  {
    img: co_kildare,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Kildare',
    bounds: 'bounds=53.45123736859129%2C-6.45996400309818%2C52.85757872275457%2C-7.168930534168536',
  },
  {
    img: co_kilkenny,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Kilkenny',
    bounds: 'bounds=52.89399432259876%2C-6.914497469740995%2C52.2428446924205%2C-7.674080766211246',
  },
  {
    img: co_laois,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Laois',
    bounds: 'bounds=53.21565216329701%2C-6.931934687617326%2C52.78126932361125%2C-7.734649450896613',
  },
  {
    img: co_leitrim,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Leitrim',
    bounds: 'bounds=54.47436672220171%2C-7.584168151100235%2C53.80596709194779%2C-8.427648967148611',
  },
  {
    img: co_limerick,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Limerick',
    bounds: 'bounds=52.75739933079389%2C-8.156315292061574%2C52.28023247426457%2C-9.367644823344087',
  },
  {
    img: co_longford,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Longford',
    bounds: 'bounds=53.94208056296073%2C-7.374903251313432%2C53.52173040773339%2C-8.035984530079483',
  },
  {
    img: co_louth,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Louth',
    bounds: 'bounds=54.11394545573901%2C-6.103010694403317%2C53.69849161613789%2C-6.695343182878104',
  },
  {
    img: co_mayo,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Mayo',
    bounds: 'bounds=54.34544322962753%2C-8.582861698857732%2C53.47192616174379%2C-10.25938089202192',
  },
  {
    img: co_meath,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Meath',
    bounds: 'bounds=53.91766880673641%2C-6.212610606579037%2C53.38186638457057%2C-7.343664218494209',
  },
  {
    img: co_monaghan,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Monaghan',
    bounds: 'bounds=54.42133875964812%2C-6.549728152094%2C53.90067982820908%2C-7.339504289213135',
  },
  {
    img: co_offaly,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Offaly',
    bounds: 'bounds=53.42427904374197%2C-6.977707654114803%2C52.84817180853907%2C-8.083872315310678',
  },
  {
    img: co_roscommon,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Roscommon',
    bounds: 'bounds=54.12523899132568%2C-7.875844540195113%2C53.27133323008708%2C-8.823716097420045',
  },
  {
    img: co_sligo,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Sligo',
    bounds: 'bounds=54.47302363012155%2C-8.153006207911572%2C53.91300937410827%2C-9.136550766503314',
  },
  {
    img: co_tipperary,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Tipperary',
    bounds: 'bounds=53.16758224931496%2C-7.37205579979839%2C52.20201443048717%2C-8.480079323983475',
  },
  {
    img: co_tyrone,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Tyrone',
    bounds: 'bounds=54.94341782489767%2C-6.419419492574852%2C54.32640955820212%2C-7.920922303863493',
  },
  {
    img: co_waterford,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Waterford',
    bounds: 'bounds=52.36389152844952%2C-6.949995648395032%2C51.937998418406%2C-8.162698718583844',
  },
  {
    img: co_westmeath,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Westmeath',
    bounds: 'bounds=53.7990522307954%2C-6.954784237238499%2C53.31796255985386%2C-7.972953078969415',
  },
  {
    img: co_wexford,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Wexford',
    bounds: 'bounds=52.79747003728824%2C-6.140779224972166%2C52.10921140421259%2C-7.018212004209762',
  },
  {
    img: co_wicklow,
    title: 'Discover Equipment Across the Emerald Isle',
    description: 'Explore equipment listings by county, making it easy to find the right tools for your projects in every corner of the country.',
    linkText: 'Equipment in',
    countryName: 'Co. Wicklow',
    bounds: 'bounds=53.23440966538965%2C-5.99803782458873%2C52.68196036949192%2C-6.792171867748397',
  },
];

export const sortedEquipment = [
  {
    img: equipment1,
    title: 'Procuring equipment is a breeze',
    description: 'Find your ideal equipment, compare, book, and pay with ease. Finalise details through HireCompare\'s messaging system.',
    linkText: 'Procure Equipment Now',
    linkPage: 'SearchPage',
  },
  {
    img: equipment2,
    title: 'Do You Own Equipment?',
    description: 'List your equipment, provide payout information, and start earning money. It’s really that easy!',
    linkText: 'List Equipment Now',
    linkPage: 'NewListingPage',
  },
]

export const programs = [
  {
    img: program1,
    title: 'Explore Abundant Opportunities',
    description: 'Our Tender Portal hosts an extensive selection of tenders, each meticulously curated by HireCompare from various vetted businesses across Ireland. \n' +
      'From urban redevelopment to rural infrastructure projects, you\'ll find tenders that match your \n' +
      'company\'s niche.',
    linkText: 'View Tender Portal',
    linkPage: 'TendersPage',
  },
  {
    img: program2,
    title: 'Effortless Equipment Procurement',
    description: 'Simplify equipment procurement effortlessly with our zero-cost procurement program. Access top-quality suppliers, ensure ISO compliance, and forge trusted connections. Compare quotes seamlessly for time and cost savings. Streamline your sourcing journey with our reliable and efficient services.',
    linkText: 'Submit A Tender',
    linkPage: 'TendersPage',
  },
]

export const metrics = [
  // {
  //   img: hires,
  //   counter: '34',
  //   title: 'Hires',
  // },
  // {
  //   img: partners,
  //   counter: '52',
  //   title: 'Partners'
  // },
  {
    img: siteVisits,
    counter: '54k',
    title: 'Site Visits'
  },
  {
    img: revenue,
    counter: '€1.2M',
    title: 'Opportunity Generated'
  },
  // {
  //   img: sales,
  //   counter: 'TBA',
  //   title: 'Sales'
  // },
  {
    img: activeListing,
    counter: '649',
    title: 'Active Listings'
  },
  {
    img: activeTenders,
    counter: '172',
    title: 'Tenders'
  },
  // {
  //   img: verifiedPlatform,
  //   counter: 'TBA',
  //   title: 'Verified Platform Users'
  // },
]

export const partnerLogos = [
  { img: partnerImg1, imgColor: partnerImg1Color, companyName: 'wicklowhire', href: 'https://prelaunch.hirecompare.ie/u/655556f0-9534-4e5b-9009-c2ae008030fa' },
  { img: partnerImg2, imgColor: partnerImg2Color, companyName: 'expert', href: 'https://prelaunch.hirecompare.ie/u/65560e76-d151-49b4-bcce-04b210359a46' },
  { img: partnerImg3, imgColor: partnerImg3Color, companyName: 'rota', href: 'https://prelaunch.hirecompare.ie/u/65560b1c-fcb8-4627-b426-6c3cee9c8c84' },
  { img: partnerImg4, imgColor: partnerImg4Color, companyName: 'builders_hoists', href: 'https://prelaunch.hirecompare.ie/u/6555594b-9e70-4fdf-91ec-c3d3e5a0caba' },
  { img: partnerImg5, imgColor: partnerImg5Color, companyName: 'cyril_j', href: 'https://prelaunch.hirecompare.ie/u/65560b2b-41f8-49ed-b27a-09d07dde3245' },
  { img: partnerImg6, imgColor: partnerImg6Color, companyName: 'kells_hire', href: 'https://prelaunch.hirecompare.ie/u/65554d5c-c6a0-40e1-89a4-0664a825bdec' },
  { img: partnerImg7, imgColor: partnerImg7Color, companyName: 'sme', href: 'https://prelaunch.hirecompare.ie/u/65560c44-3906-4c54-bb27-be0c25615898' },
  { img: partnerImg8, imgColor: partnerImg8Color, companyName: 'vms', href: 'https://prelaunch.hirecompare.ie/u/655553ed-8b8a-45cd-bbcc-f7215eb8598d' },
]
