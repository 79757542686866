import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import image from '../images/brandmark.png';

import css from './SectionGetToKnow.module.css';
import { NamedLink } from '../../../components';
import rightArrow from '../images/arrows/right.svg';

const SectionGetToKnow = props => {

  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <h3 className={css.mainTitle}>
          <FormattedMessage id="SectionGetToKnow.title" />
        </h3>
        <h2 className={css.subTitle}>
          <FormattedMessage id="SectionGetToKnow.subTitle" />
        </h2>

        <div className={css.block}>
          <div className={css.blockTitleHolder}>
            <h4 className={css.blockTitle}>
              <FormattedMessage id="SectionGetToKnow.blockTitle" />
            </h4>
            <p className={css.blockDescription}>
              <FormattedMessage id="SectionGetToKnow.description" />
            </p>

            <NamedLink className={css.blockLink} name="AboutUs">
              <FormattedMessage id="SectionGetToKnow.more" />
              <span className={css.rightArrow} />
            </NamedLink>
          </div>

          <div className={css.blockImageHolder}>
            <img className={css.blockImage} src={image} alt='Brand Mark Image' />
          </div>





        </div>

      </div>
    </div>
  );
};

SectionGetToKnow.defaultProps = { rootClassName: null, className: null };

SectionGetToKnow.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionGetToKnow;
