import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import { programs } from '../data';
import { NamedLink } from '../../../components';

import css from './SectionProgram.module.css';

const BlockItems = ({ history }) => {
  return programs.map((item, index) => {
    return (
      <li key={index} className={css.itemHolder}>
        <div className={css.itemTitleHolder}>
          <h3 className={css.itemTitle}>{item.title}</h3>
          <p className={css.itemDescription}>{item.description}</p>
        </div>

        <div className={css.itemImageHolder}>
          <img className={css.itemImage} src={item.img} alt={item.title} />
        </div>
        {index === 0 ? (
          <NamedLink
            className={
              index === 0 ? classNames(css.itemLink, css.itemLinkPurple) : classNames(css.itemLink, css.itemLinkSecond)
            }
            name={item.linkPage}
          >
            {item.linkText}
            <span className={css.rightArrow} />
          </NamedLink>
        ) : (
          <div
            className={
              index === 0 ? classNames(css.itemLink) : classNames(css.itemLink, css.itemLinkSecond)
            }
            onClick={() => history.push('/p/tender-submissions')}
          >
            {item.linkText}
            <span className={css.rightArrow} />
          </div>
        )}
      </li>
    );
  });
};

const SectionProgram = props => {
  const { rootClassName, className, history } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <h3 className={css.mainTitle}>
          <FormattedMessage id="SectionProgram.title" />
        </h3>
        <h3 className={css.mainTitle}>
          <FormattedMessage id="SectionProgram.title2" />
        </h3>
        <h4 className={css.subTitle}>
          <FormattedMessage id="SectionProgram.subTitle" />
        </h4>

        <ul className={css.itemsList}>
          <BlockItems history={history} />
        </ul>
      </div>
    </div>
  );
};

SectionProgram.defaultProps = { rootClassName: null, className: null };

SectionProgram.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionProgram;
