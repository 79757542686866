import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import { categories, locations } from '../data';


import css from './SectionLocations.module.css';
import AliceCarousel from 'react-alice-carousel';
import { NamedLink } from '../../../components';
import seeMore from '../images/arrows/rightBlue.svg';
import rightArrow from '../images/arrows/rightBlack.svg';
import leftArrow from '../images/arrows/leftBlack.svg';

const items = locations.map((item) => {
  return <div key={item.title} className={css.itemCarousel}>
    <div className={css.itemCarouselTitleHolder}>
      <h3 className={css.itemCarouselTitle}>{item.title}</h3>
      <p className={css.itemCarouselDescription}>{item.description}</p>
      <NamedLink className={css.itemCarouselLink} name="SearchPage">
        {item.linkText} <span>{item.countryName}</span>
        <span className={css.rightArrow} />
        {/*<img className={css.rightArrow} src={seeMore} alt='Arrow' />*/}
      </NamedLink>
    </div>
  </div>
});

const thumbs = locations.map((thumb) => {
  return <div className={css.thumbItem}>
    <img className={css.thumbImg} src={thumb.img} alt='' />
  </div>
})

const SectionLocations = props => {

  const { rootClassName, className } = props;

  const [mainIndex, setMainIndex] = useState(0);
  const [mainAnimation, setMainAnimation] = useState(false);
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);

  const slideNext = () => {
    if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex + 1);
    }
  };

  const slidePrev = () => {
    if (!thumbAnimation && thumbIndex > 0) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex - 1);
    }
  };

  const syncThumbs = (e) => {
    setThumbIndex(e.item);
    setThumbAnimation(false);

    if (!mainAnimation) {
      setMainIndex(e.item);
    }
  };


  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.items}>

          <div className={css.itemCarouselTitleHolder}>
            <h3 className={css.itemCarouselTitle}>{locations[thumbIndex].title}</h3>
            <p className={css.itemCarouselDescription}>{locations[thumbIndex].description}</p>
            <NamedLink className={css.itemCarouselLink} name="SearchPage" to={{search: `?${locations[thumbIndex].bounds}`}}>
              {locations[thumbIndex].linkText} <span className={css.countryName}>{locations[thumbIndex].countryName}</span>
              <span className={css.rightArrow} />
            </NamedLink>
          </div>
        </div>

        <div className={css.thumbs}>
          <AliceCarousel
            activeIndex={thumbIndex}
            autoWidth
            disableDotsControls
            disableButtonsControls
            items={thumbs}
            mouseTracking={false}
            onSlideChanged={syncThumbs}
            touchTracking={!mainAnimation}
          />
          <div className={css.buttonsHolder}>
            <button className={css.buttonPrev} onClick={slidePrev}>
              <img className={css.buttonArrow} src={leftArrow} alt='Arrow' />
            </button>
            <div className={css.counter}>
              <span>{thumbIndex + 1}/</span>
              <span>{locations.length}</span>
            </div>
            <button className={css.buttonNext} onClick={slideNext}>
              <img className={css.buttonArrow} src={rightArrow} alt='Arrow' />
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
